<template>
  <div class="row justify-content-center">
    <div class="col-md-6">
      <v-card elevation="8">
        <v-card-title>
          <h3 class="mb-0">Formulario</h3>
        </v-card-title>
        <v-divider></v-divider>
        <div class="card-body">
          <div class="row form-group">
            <div class="col-md-3">
              <label>Valor:*</label>
              <input
                required=""
                type="text"
                class="form-control"
                v-model="valor"
                placeholder="Valor..."
                v-mask="variableWithMask2"
              />
            </div>
            <div class="col-md-9">
              <label>Mensagem:*</label>
              <input
                required=""
                type="text"
                class="form-control"
                v-model="form.descricao"
                placeholder="Descrição..."
              />
            </div>
            <div class="col-md-3">
              <label>Tipo Ação:*</label>
              <select
                v-model="form.tipo"
                class="form-control"
                placeholder="Selecione"
              >
                <option value="0" disabled>Selecione</option>
                <option value="1">Saque</option>
                <option value="2">Deposito</option>
              </select>
            </div>
            <div v-show="form.tipo" class="col-md-4">
              <label>Data {{ form.tipo == 1 ? "Saque" : "deposito" }}:*</label>
              <input
                required=""
                type="date"
                class="form-control"
                id="form_control_1"
                v-model="form.data"
                placeholder="Data de nascimento..."
              />
            </div>
            <div v-if="form.tipo == 2" class="col-md-5">
              <InputFile class="pt-6" text="Comprovante" ref="comprovante" />
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-md-2 text-center">
              <v-btn
                color="primary"
                elevation="6"
                :loading="verif"
                @click.prevent="confirm('cria')"
                class="btn btn-primary"
                :disabled="verif"
                >salvar</v-btn
              >
            </div>
          </div>
        </div>
      </v-card>
    </div>
    <div class="col-md-6">
      <v-card elevation="8">
       
        <v-card-title class="card-header justify-content-between">
          <h3 class="mb-0">Gerenciar Contas</h3>
          <v-btn @click="calc()"  color="warning" elevation="6"> Calcular Aliquota</v-btn>
          
        </v-card-title>
        <!-- <v-divider></v-divider> -->

        <div class="card-body">
          <b-table
            :fields="['cliente', 'documento', 'tipo', 'status', 'saldo']"
            :items="[conta]"
            class="
              table
              table-head-custom
              table-vertical-center
              table-head-bg
              table-borderless
            "
            show-empty
            empty-text="Nenhum registro encontrado!"
          >
            <template #cell(saldo)="data">
              <span>
                {{ data.item.saldo | formatMoney }}
              </span>
            </template>
            <template #cell(status)="data">
              <span
                class="label label-lg label-inline px-2 pb-2 pt-3"
                :class="{
                  'label-light-success': data.item.status === 1,
                  'label-light-danger': data.item.status === 0,
                  'label-light-warning': data.item.status === 2,
                }"
              >
                {{ data.item.status === 1 ? "Ativo" : "Inativo" }}
              </span>
            </template>
            <template #cell(tipo)="data">
              <span
                class="label label-lg label-inline px-2 pb-2 pt-3"
                :class="{
                  'label-light-success': data.item.tipo === 1,
                  'label-light-info': data.item.tipo === 2,
                }"
              >
                {{ data.item.status === 1 ? "PF" : "PJ" }}
              </span>
            </template>
          </b-table>
        </div>
      </v-card>
    </div>
    <div class="col-md-10">
      <v-card elevation="8">
        <v-card-title class="card-header">
          <h3 class="mb-0">
            Movimentações da conta {{ conta.cliente }} - {{ conta.documento }}
          </h3></v-card-title
        >
        <div class="card-body">
          <b-table
            :fields="['tipo', 'status', 'valor', 'data', 'acoes']"
            :items="lista_mov"
            :per-page="perPage"
            :current-page="currentPage"
            id="mov-table"
            class="
              table
              table-head-custom
              table-vertical-center
              table-head-bg
              table-borderless
            "
            show-empty
            empty-text="Nenhum registro encontrado!"
          >
            <template #cell(status)="data">
              <span
                class="label label-lg label-inline px-2 pb-2 pt-3"
                :class="{
                  'label-light-success': data.item.status === 1,
                  'label-light-danger': data.item.status === 0,
                  'label-light-warning': data.item.status === 2,
                }"
              >
                {{
                  data.item.status === 1
                    ? "Aprovado"
                    : data.item.status === 2
                    ? "Endamento"
                    : "Negado"
                }}
              </span>
            </template>
            <template #cell(valor)="data">
              <span>
                {{ data.item.valor | formatMoney }}
              </span>
            </template>
            <template #cell(tipo)="data">
              <span
                class="label label-lg label-inline px-2 pb-2 pt-3"
                :class="{
                  'label-light-success': data.item.status === 1,
                  'label-light-warning': data.item.status === 2,
                }"
              >
                {{ parseInt(data.item.tipo) === 1 ? "Saque" : "Deposito" }}
              </span>
            </template>
            <template #head(acoes)><span></span></template>
            <template #cell(acoes)="{ item }">
              <div class="text-right w-100">
                <v-tooltip
                  v-for="(btn, index) in lista_btn"
                  :key="index"
                  top
                  :color="btn.color"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-show="lista_permissoes_filial[btn.permisao]"
                      fab
                      outlined
                      class="mr-1"
                      @click="acoes(item, btn.button)"
                      :color="btn.color"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <i :class="btn.icon"></i>
                    </v-btn>
                  </template>
                  <span>{{ btn.title }}</span>
                </v-tooltip>
              </div>
            </template>
          </b-table>

          <b-pagination
            v-model="currentPage"
            :total-rows="lista_mov.length"
            :per-page="perPage"
            aria-controls="mov-table"
          >
          </b-pagination>
        </div>
      </v-card>
    </div>
    <v-dialog
      v-if="movimento != null"
      v-model="modalAprovacao"
      max-width="900"
      persistent
    >
      <template>
        <v-card>
          <v-card-title>Regras e Permissoes</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div class="col-md-11">
              <h1>{{ movimento.valor | formatMoney }}</h1>

              <h2 class="" v-if="!movimento.arquivos_id">
                <span
                  class="label label-lg label-inline px-2 pb-4 pt-3 h2 ml-2"
                  :class="
                    movimento.status == 1
                      ? '    label-light-success'
                      : 'label-light-warning'
                  "
                >
                  {{ movimento.status == 1 ? "Aprovado" : "Aprovar" }}
                  {{ movimento.tipo == 1 ? "Saque" : "Deposito" }}
                </span>
              </h2>
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card
                    v-if="movimento.arquivos_id"
                    :elevation="hover ? 24 : 6"
                    class="mx-auto pa-6"
                  >
                    <!-- <viewer :images="images"> -->
                    <!-- <img v-for="src in images" :key="src" :src="src" /> -->

                    <embed
                      v-if="movimento.arquivos_id"
                      :src="host + movimento.arquivos_id + '?token=' + token"
                      frameBorder="1"
                      height="400px"
                      width="100%"
                    />
                    <!-- <img    :src="documento.caminho" alt=""> -->
                    <!-- </viewer> -->
                    <!-- api -->
                  </v-card></template
                ></v-hover
              >
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="modalAprovacao = false">
              Cancelar
            </v-btn>
            <v-btn
              v-if="movimento.status == 2"
              color="blue darken-1"
              text
              @click="confirm('aprov')"
            >
              Aprovar
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import InputFile from "@/core/helpers/InputFile";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import JwtService from "@/core/services/jwt.service";
import moneyFormat from "@/core/mixins/moneyFormat";

export default {
  components: { InputFile },
  mixins: [moneyFormat, fireAlert],
  data() {
    return {
      verif: false,
      currentPage: 1,
      perPage: 10,
      variableWithMask2: "",
      form: {
        descricao: null,
        valor: null,
        arquivos_id: null,
        tipo: 0,
        contas_usuario_id: null,
      },
      valor: null,
      lista_btn: [
        {
          permisao: "r_Contas",
          button: "aprov",
          title: "Vizualizar Comprovante",
          color: "primary",
          icon: "fa fa-eye text-primary",
        },
      ],
      movimento: null,
      modalAprovacao: false,
      host: process.env.VUE_APP_API_URL + "arquivo/doc/",
      token: JwtService.getToken(),
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Gerenciar Contas" }]);
  },

  watch: {
    valor: function () {
      let pa = "#";
      for (let i = 0; i < this.valor.length; i++) {
        if (i > 6) {
          pa = pa + "#";
        }
      }
      this.variableWithMask2 = "R$" + pa + "#,##";
      console.log(this.variableWithMask2);
      this.form.valor = parseFloat(
        this.valor.replace("R$", "").replace(",", ".")
      );
    },
  },
  computed: {
    lista_mov() {
      return this.$store.state.movimentacao.lista_mov;
    },
    conta() {
      return this.$store.state.contas.conta;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.movimentacao.mensagem_alert;
    },
    mensagem_alert_aliquota() {
      return this.$store.state.aliquotas.mensagem_alert;
    },
    calc_aliquota(){
      return this.$store.state.aliquotas.calc_aliquota;

    }
  },
  created() {
    this.listar_mov();
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Tem Certeza?",
        message:
          tipo == "cria"
            ? `Essa Solicitação de ` +
              (this.form.tipo == 1 ? `saque ` : `deposito `) +
              ` no saldo do cliente no sistema?`
            : "Essa Solicitação aprova um " +
              (this.movimento.tipo == 1 ? `saque ` : `deposito `) +
              ` no saldo do cliente no sistema?`,
        button: {
          no: "Não",
          yes: "Sim",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "aprov") this.aprovarMovimento();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      if (this.$refs.comprovante) {
        this.form.arquivos_id = await this.$refs.comprovante.submit();
      }
      this.form.contas_usuario_id = this.conta.id;
      await this.$store.dispatch("movimentacao/create_mov", this.form);
      this.verif = false;
      this.fireAlert({ ...this.mensagem_alert });
    },
    async createMovimentacao() {
      if (this.$refs.arquivoAlvaraInput) {
        this.form.arquivos_id = await this.$refs.comprovante.submit();
      }
    },
    async listar_mov() {
      if (this.conta.cliente) {
        await this.$store.dispatch("movimentacao/listar_mov", this.conta);
      } else {
        this.$router.push({ name: "contas" });
      }
    },
    atualizar(value) {
      this.$router.push({ name: "createContas" });
      this.$store.dispatch("configEmpresa/atualizar", value);
    },

    acoes(item, tipo) {
      if (tipo == "aprov") {
        this.movimento = item;
        this.modalAprovacao = true;
        console.log(this.movimento);
      }
    },

    async aprovarMovimento() {
      await this.$store.dispatch("movimentacao/aprovar_mov", this.movimento);
      this.fireAlert(this.mensagem_alert);
      console.log(this.mensagem_alert);
      this.modalAprovacao = false;
    },
    async calc(){
      await this.$store.dispatch("aliquotas/calc_aliquota", this.conta.id);
      this.$store.dispatch("contas/set_conta", this.calc_aliquota);
      console.log(this.calc_aliquota)

      this.fireAlert(this.mensagem_alert_aliquota);
    }
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>